/*eslint-disable */
// import InterestCategoryModel from '../../Model/InterestCategory'
import request from '../../Utils/curl'

let InterestCategorys = {
  /**
   * interestCategoryList
   */
  async interestCategoryList (context) {
    try{
      let post_data = new FormData();

      return await request.curl(context, "interestCategory_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at interestCategoryList() and Exception:',err.message)
    }
  },

  /**
   * interestCategoryView
   */
  async interestCategoryView (context, interestCategoryId) {
    try {

      let post_data = new FormData();
      post_data.append('interest_cat_id', interestCategoryId);
      return await request.curl(context, "interestCategory_view", post_data)
        .then(async response => {
          return response;
        });
    }
    catch (err) {
      console.error("Exception occurred at interestCategoryList() and Exception:",err.message)
    }

  },

  /**
   * interestCategoryAdd
   */
  async interestCategoryAdd (context, interestCategoryObj) {
    try{
      let post_data = new FormData();

      for (let key in interestCategoryObj) {
        post_data.append(key, interestCategoryObj[key]);
      }

      return await request.curl(context, "interestCategory_add", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestCategoryAdd() and Exception:',err.message)
    }
  },

  /**
   * interestCategoryEdit
   */
  async interestCategoryEdit (context, interestCategoryObj) {
    try{
      let post_data = new FormData();

      for (let key in interestCategoryObj) {
        post_data.append(key, interestCategoryObj[key]);
      }

      return await request.curl(context, "interestCategory_edit", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestCategoryEdit() and Exception:',err.message)
    }
  },

  /**
   * interestCategoryDelete
   */
  async interestCategoryDelete (context, interestCategoryId) {
    try{
      let post_data = new FormData();

      post_data.append('interest_cat_id', interestCategoryId);

      return await request.curl(context, "interestCategory_delete", post_data)
        .then(async response => {
          return response;
        });
    }
    catch(err){
      console.error('Exception occurred at interestCategoryDelete() and Exception:',err.message)
    }
  }
}

export {
  InterestCategorys
}

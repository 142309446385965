/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{ cvCardTitle }}
            </h4>
          </template>
          <template>
            <p v-if="!propOpenedInModal">
              {{ cvCardSubHeader }}
            </p>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationinterest_cat_name">
                    {{cvInterestCatNameLabel}}</label>
                  <input
                    v-model="vmInterestCategoryFormData.interest_cat_name"
                    type="text"
                    class="form-control"
                    required/>
                </div>
              </div>
              <div class="form-group">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="interestCategoryAdd()">
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { InterestCategorys } from "../../../FackApi/api/interestCategory"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "InterestCategoryAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      apiName: "interestCategory_add",
      cvCardTitle: "Add interestCategory",
      cvCardSubHeader: "Add Interest Category",
      cvSubmitBtn: "Add",
      cvInterestCatNameLabel: "Interest category",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "InterestCategory",
      vmInterestCategoryFormData: Object.assign({}, this.initFormData()),
      vmInterestCategoryDesc: null,
      vmInterestCategoryType: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
  // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "interest_cat_name": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmInterestCategoryFormData) {
          if (!this.vmInterestCategoryFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * interestCategoryAdd
     */
    async interestCategoryAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        let interestCategoryAddResp = await InterestCategorys.interestCategoryAdd(this, this.vmInterestCategoryFormData)
        await ApiResponse.responseMessageDisplay(this, interestCategoryAddResp)
        if (interestCategoryAddResp && !interestCategoryAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseInterestCategoryAddModal", interestCategoryAddResp.resp_data)
        }
        this.vmInterestCategoryFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at interestCategoryAdd() and Exception:", err.message)
      }
    }
  }
}
</script>
